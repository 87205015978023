<template>
  <div class="container-fluid d-flex flex-column flex-grow-1 mt-3">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit"></i>Users Add/Edit Page
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <b-row>
                    <b-col sm="3">
                      <TextBox v-model="item.firstName">First Name</TextBox>
                    </b-col>
                    <b-col sm="3">
                      <TextBox v-model="item.lastName">Last Name</TextBox>
                    </b-col>
                    <b-col sm="3">
                      <TextBox v-model="item.phone">Phone</TextBox>
                    </b-col>
                    <b-col sm="3">
                      <SelectBox :items="statuses" v-model="item.status">Status</SelectBox>
                    </b-col>
                    <b-col sm="4">
                      <h3>Small Image</h3>
                      <div class="profile-img-upload">
                        <img v-if="profileImage" :src="profileImage" alt="img" style="width: 150px">
                        <img v-else src="../../assets/img/no-image.png" alt="img" style="width: 150px">
                        <input type="file" style="display: none"
                               id="profileImageUpload"
                               v-on:input="profileImageChoose">
                        <label for="profileImageUpload">
                                            <span class="profile-img-upload-title">
                                                <i class="fa fa-camera mr-2"></i>
                                                <span style="cursor: pointer">Change</span>
                                            </span>
                        </label>
                      </div>
                    </b-col>
                  </b-row>
                  <div class="d-flex justify-content-end">
                    <div class="form-actions">
                      <b-button right type="submit" variant="primary" @click="onSubmit">Save</b-button>
                      <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/item/list'">Cancel</router-link>
                    </div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'UsersListPage',
    data() {
      return {
        item: {},
        profileImage: '',
        statuses: [{value: 'ACTIVE', text: 'Active'}, {value: 'INACTIVE', text: 'Inactive'}]
      };
    },
    created() {
      if (!this.$route.params.id || this.$route.params.id <= 0) {
        return;
      }
      this.axios.get('member/item', {params: {id: this.$route.params.id}})
          .then(response => {
            this.item = response.data.data;
            if (this.item.image) {
              this.profileImage = this.item.image.imageURL;
            }
          })
          .catch(error => {
            if (error.response && error.response.data.status === 400) {
              this.$toastr.error(error.response.data.message);
            }
          });
    },
    methods: {
      onSubmit(event) {
        event.preventDefault();
        let axios;
        this.item.password = null
        if (this.item.id) {
          axios = this.axios.put('member/item', this.item);
        } else {
          axios = this.axios.post('member/item', this.item);
        }
        axios.then((res) => {
          if (res.data && res.data.status === 200) {
            this.$toastr.success(res.data.message);
            this.$router.push('/user/list');
          } else {
            this.$toastr.error(res.data.message);
          }
        }).catch((error) => {
          if (error.message) {
            this.$toastr.error(error.message);
          }
        });
      },

      profileImageChoose(event) {
        this.selectedFile = event.target.files[0];
        const formData = new FormData();
        formData.append('uploadFile', this.selectedFile);

        this.axios.post('public/upload/file',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        )
            .then((response) => {
              if (response.data && response.data.data) {
                this.item.image = {id: response.data.data.id};
                this.profileImage = response.data.data.imageURL;
              }
            });
      }
    },
  };
</script>

<style scoped>

</style>